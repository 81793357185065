import axios from 'axios';
import { useState, useEffect } from 'react';
import AutocompleteMuiCustom from '../../../components/common/AutocompleteMuiCustom';

const DefectListComponent = ({
  process,
  setRender,
  processStageName,
  isView,
}: {
  process: any;
  setRender: any;
  processStageName: any;
  isView: any;
}) => {
  const [defectData, setDefectData] = useState([]);
  useEffect(() => {
    axios
      .get(
        `/api/defectRoutes/defectList/defectByStage/${encodeURI(processStageName)}`,
      )
      .then((res) => {
        setDefectData(res.data);
      })
      .catch((err) => console.log(err));
  }, [processStageName]);

  return (
    <AutocompleteMuiCustom
      id={'defect_name'}
      label=""
      option_name="defect_name"
      arrayofObj={defectData}
      value={process.defect_id || ''}
      onChange={(e, value) => {
        process.defect_id = value;
        setRender((prev: any) => !prev);
      }}
    />
  );
};

export default DefectListComponent;

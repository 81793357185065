import { useEffect, useRef, useState } from 'react';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import moment from 'moment';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import { calMinute, convToTime } from '../../../../../utils/helpers';
import Operator from '../Operator';
import ToolView from './ToolView';
import ModalCustom from '../../../../../components/common/Modal';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { useQuery } from '../../../../../hooks/UseQuery';
import axios from 'axios';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import { Grid } from '@mui/material';
interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
  setIsOpenPopupRejections: any;
  setIsOpenPopupForScheduleDowntime: any;
  setRejectionsIndx: any;
  setIsOpenPopup: any;
  setPartIdx: any;
}

const calculateWorkingHour = (process: any): number | null => {
  if (!(process.shift_id?.start_time && process.shift_id?.end_time))
    return null;
  const differenceInMinutes = calMinute(
    process.shift_id?.start_time,
    process.shift_id?.end_time,
  );

  if (!differenceInMinutes) return null;
  const workingHour = differenceInMinutes - (process.schedule_down_time || 0);
  return workingHour;
};

const calculateTotalRunTime = (process: any): number | null => {
  if (!(process.shift_id?.start_time && process.shift_id?.end_time))
    return null;
  const differenceInMinutes = calMinute(
    process.shift_id?.start_time,
    process.shift_id?.end_time,
  );

  if (!differenceInMinutes) return null;
  // Calculate total run time
  const totalRunTime =
    differenceInMinutes -
    (process.schedule_down_time || 0) -
    (process.unschedule_down_time || 0);
  return totalRunTime;
};
const fmtString = (arr: any = []) => {
  return arr.map((tool: any, index: number) => {
    if (index === arr.length - 1) return tool.tool_desc;
    return `${tool.tool_desc},`;
  });
};
// process.job_id?.part_id?.weight_pc
const producedQtyToFloor = (process: any) => {
  const producedQty =
    (+process.ok_without_chips_kgs || 0) +
    (+process.chips_qty_kgs || 0) +
    (+process.hold_qty_kgs || 0);
  return Math.floor(producedQty);
};
const calculateOkQtyWithoutChips = (process: any) => {
  if (process.ok_without_chips_kgs && process.part_id?.weight_pc) {
    const okQtyWithoutChips =
      (+process.ok_without_chips_kgs || 0) / process?.part_id?.weight_pc;
    process.ok_without_chips = Math.floor(okQtyWithoutChips);
    return Math.floor(okQtyWithoutChips);
  }
};

const calculateHoldQty = (process: any) => {
  if (process.hold_qty_kgs && process?.part_id?.weight_pc) {
    const holdQty = (+process.hold_qty_kgs || 0) / process?.part_id?.weight_pc;
    return Math.floor(holdQty);
  }
};

const TBody = ({
  moduleData,
  isView,
  handelDeleteRows,
  setIsOpenPopupRejections,
  setRejectionsIndx,
  setIsOpenPopupForScheduleDowntime,
  setIsOpenPopup,
  setPartIdx,
}: IProps) => {
  const [render, setRender] = useState(false);
  const [isToolOpenPopup, setIsToolOpenPopup] = useState(false);
  const [shiftData, setShiftData] = useState([]);
  const toolIds = useRef([]);
  const toolCategoryIds = useRef([]);
  const date = useQuery('date');

  // Parse the date to get the month and year
  const parsedDate = moment(date);
  const month = parsedDate.month(); // months are 0-based in moment.js
  const year = parsedDate.year();

  const startOfMonth = moment({ year, month, day: 1 });
  const endOfMonth = moment(startOfMonth).endOf('month');

  useEffect(() => {
    axios
      .get(`/api/shiftSchedulingRoute/autoComplete/data`)
      .then((res) => {
        if (res.data) {
          setShiftData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <tr key={index + 'module data'}>
              <td width={'100px'} style={{ fontWeight: 'bold' }}>
                {index + 1}
              </td>
              <td style={{ minWidth: '170px' }}>
                {isView ? (
                  formatDate(process.dailyProduction_date)
                ) : (
                  <DatePickerMui
                    label=""
                    value={moment(process.dailyProduction_date || null)}
                    minDate={startOfMonth}
                    maxDate={endOfMonth}
                    onChange={(date) => {
                      process.dailyProduction_date = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td>
              <td style={{ minWidth: '150px' }}>
                <AutocompleteMuiCustom
                  id="shift"
                  label=""
                  option_name="shift"
                  arrayofObj={shiftData || []}
                  value={process?.shift_id || ''}
                  onChange={(e, value) => {
                    process.shift_id = value;
                    setRender((prev: any) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                {convToTime(process.shift_id?.start_time)}
              </td>
              <td style={{ minWidth: '100px' }}>
                {convToTime(process.shift_id?.end_time)}
              </td>

              <td style={{ minWidth: '100px' }}>
                {process.schedule_down_time}
              </td>
              <td
                style={{
                  width: '300px',
                  minWidth: '300px',
                }}
              >
                <div
                  style={{
                    ...(!isView && { display: 'flex' }),
                    ...(!isView && { justifyContent: 'space-between' }),
                  }}
                >
                  <div style={{ border: 'none' }}>
                    {process?.schedule_details?.map((item: any) => {
                      return item?.reason ? (
                        <span>
                          {item?.reason} - {item?.schedule_min}
                          <br />
                          <hr />
                        </span>
                      ) : null;
                    })}
                  </div>
                  {!isView && (
                    <AddIconButton
                      tooltipTitle="add reasons and schedule downtime"
                      onClick={() => {
                        setIsOpenPopupForScheduleDowntime(true);
                        setRejectionsIndx(index);
                      }}
                    />
                  )}
                </div>
              </td>
              <td style={{ minWidth: '150px' }}>
                {process.unschedule_down_time}
              </td>
              <td
                style={{
                  width: '300px',
                  minWidth: '300px',
                }}
              >
                <div
                  style={{
                    ...(!isView && { display: 'flex' }),
                    ...(!isView && { justifyContent: 'space-between' }),
                  }}
                >
                  <div style={{ border: 'none' }}>
                    {process?.unschedule_details?.map((item: any) => {
                      return item?.reason ? (
                        <span>
                          {item?.reason} - {item?.unschedule_min}
                          <br />
                          <hr />
                        </span>
                      ) : null;
                    })}
                  </div>
                  {!isView && (
                    <AddIconButton
                      tooltipTitle="add reasons and schedule downtime"
                      onClick={() => {
                        setIsOpenPopupRejections(true);
                        setRejectionsIndx(index);
                      }}
                    />
                  )}
                </div>
              </td>
              <td style={{ minWidth: '150px' }}>
                <TableInput
                  isView={isView}
                  name="prod_order_no"
                  type="text"
                  value={process.prod_order_no}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ width: '250px', minWidth: '250px' }}>
                {isView ? (
                  process?.part_id && process?.part_id?.item_no
                ) : (
                  <Grid container direction="row" alignItems={'center'}>
                    <Grid item xs={10}>
                      <p style={{ fontSize: '1.6rem' }}>
                        {process?.part_id?.item_no || 'Pick Part Number'}
                      </p>
                    </Grid>
                    <Grid item xs={2}>
                      {process.part_id ? (
                        <RemoveIconButton
                          tooltipTitle="remove part"
                          onClick={async () => {
                            process.part_id = null;
                          }}
                        />
                      ) : (
                        <AddIconButton
                          tooltipTitle="add part"
                          onClick={() => {
                            setIsOpenPopup(true);
                            setPartIdx(index);
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                )}
              </td>
              <td style={{ minWidth: '200px' }}>
                {fmtString(process?.part_id?.tool_item_ids)}
              </td>
              <td style={{ minWidth: '200px' }}>
                {moduleData.machine_id && (
                  <Operator
                    field="operator"
                    machineId={moduleData.machine_id?._id}
                    subDepartmentId={moduleData.machine_id?.sub_department_id}
                    {...{ isView, process }}
                  />
                )}
              </td>

              <td style={{ minWidth: '100px' }}>
                {calculateWorkingHour(process)}
              </td>
              <td>{calculateTotalRunTime(process)}</td>
              <td style={{ minWidth: '100px' }}>
                {producedQtyToFloor(process)}
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="ok_without_chips_kgs"
                  type="number"
                  value={process.ok_without_chips_kgs}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="chips_qty_kgs"
                  type="number"
                  value={process.chips_qty_kgs}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="hold_qty_kgs"
                  type="number"
                  value={process.hold_qty_kgs}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>{process?.part_id?.weight_pc?.toFixed(2)}</td>
              <td style={{ minWidth: '100px' }}>
                {calculateOkQtyWithoutChips(process)}
              </td>
              <td style={{ minWidth: '100px' }}>
                {' '}
                {calculateHoldQty(process)}
              </td>
              <td style={{ minWidth: '200px' }} colSpan={isView ? 2 : 1}>
                <TableInput
                  isView={isView}
                  name="remarks"
                  type="text"
                  value={process.remarks}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                {!isView && (
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                )}
              </td>
            </tr>
          ))}
      </tbody>
      <ModalCustom
        title="TOOL LIST"
        openModal={isToolOpenPopup}
        closeModal={() => {
          setIsToolOpenPopup(false);
        }}
      >
        <ToolView
          toolIds={toolIds.current}
          toolCategoryIds={toolCategoryIds.current}
        />
      </ModalCustom>
    </>
  );
};

export default TBody;

import { Box } from '@mui/system';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CustomButton,
  SubmitButton,
} from '../../components/common/button/CustomButtons';
import useConfirm from '../../components/common/useConfirm';
import ChildHeader from '../../components/ui/ChildHeader';
import { useQuery } from '../../hooks/UseQuery';
import TController from './components/TController';
import lodash from 'lodash';
import PickPart from '../../components/ui/PickPart';
import ModalCustom from '../../components/common/Modal';
import { processes } from './helpers/initialState';
import { usePermission } from '../../utils/helpers/usePermission';
import formatDate from '../../components/common/formatDate';
import moment from 'moment';
import { generateExcel } from './components/excelGenerator';
import { parseExcel } from './components/ExcelParser';

const Create = () => {
  const navigate = useNavigate();
  const [moduleData, setModuleData] = useState<any>({
    processes: [], //Array.from({ length: 1 }, () => ({ ...processes[0] })),
  });
  const isView = useQuery('isView') === 'true' ? true : false;
  const permissions = usePermission(16);
  // const isView = permissions?.includes('edit') ? false : true;
  const annual_month = useQuery('annual_date') || '';
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [partIdx, setPartIdx] = useState<number>(0);
  const [useEffectRender, setUseEffectRender] = useState(false);
  const [DialogAddRow, confirmAddRow] = useConfirm(
    'Add Row',
    'Are you sure you want to Add Row?'
  );
  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?'
  );
  const [render, setRender] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    axios
      .get(`/api/ncReport/${annual_month}`, moduleData)
      .then((res) => {
        if (res.data) {
          setModuleData(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, [useEffectRender]);

  const onSubmit = async () => {
    return new Promise(async (resolve: any, reject: any) => {
      if (moduleData._id) {
        await axios
          .put(`/api/ncReport/${moduleData._id}`, moduleData)
          .then((res) => {
            if (res.data) {
              alert('updated successfully ');
              setUseEffectRender((prev) => !prev);
              resolve();
            }
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      } else {
        if (!annual_month) {
          return alert('Please pick annual year?');
        }
        moduleData.annual_month = annual_month;
        await axios
          .post(`/api/ncReport`, moduleData)
          .then((res) => {
            alert('created successfully');
            setUseEffectRender((prev) => !prev);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      }
    });
  };
  const handelDeleteRows = async (rowIdx: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    let data = { ...moduleData };
    data.processes.splice(rowIdx, 1);
    setModuleData(data);
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (!event.target.files?.[0]) return;
      
      setIsUploading(true);
      const file = event.target.files[0];
      const parsedData = await parseExcel(file);
      
      await axios.post('/api/ncReport/upload', parsedData);
      alert('Excel data uploaded successfully');
      setUseEffectRender(prev => !prev);
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Error uploading file');
    } finally {
      setIsUploading(false);
    }
  };

  const exportToExcel = async () => {
    try {
      console.log("Starting Excel export...");

      const headers = [
        'S.NO.',
        'DATE',
        'TIME',
        'STAGE',
        'MACHINE NO.',
        'PRODUCTION ORDER NO.',
        'ITEM CODE',
        'SUSPECTED QTY',
        'UOM',
        'NATURE OF NC',
        'CONTAINMENT ACTION',
        'ROOT CAUSE',
        'Action Taken',
        'OK QTY',
        'SCRAP QTY',
        'REWORKED QTY',
        'ACCEPTED UD QTY',
        'QA SIGN',
        'PRD SIGN',
        'REMARKS'
      ];

      console.log("Headers prepared:", headers);

      const data = moduleData.processes.map((process: any, index: number) => [
        index + 1,
        formatDate(process.date),
        moment(process.time).format('hh : mm : ss'),
        process.stage,
        process.machine_no,
        process.production_order_no,
        process.item_code,
        process.suspected_qty,
        process.uom,
        process.defect_id?.defect_name,
        process.containment_action,
        process.root_cause,
        process.action_taken,
        process.ok_qty,
        process.scrap_qty,
        process.reworked_qty,
        process.accepted_qty,
        process.qa_sign,
        process.prd_sign,
        process.remarks,
      ]);

      console.log("Data prepared:", data);

      console.log("Calling generateExcel function...");
      const blob = await generateExcel({
        headers,
        data,
        sheetName: 'Non Conformance Report',
        fileName: 'non_conformance_report.xlsx'
      });

      console.log("Excel blob generated:", blob);

      const url = window.URL.createObjectURL(blob);
      console.log("Blob URL created:", url);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `non_conformance_report_${moduleData.annual_month}.xlsx`);
      document.body.appendChild(link);
      
      console.log("Triggering download...");
      link.click();
      
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      
      console.log("Excel export completed successfully.");
    } catch (error) {
      console.error('Error in exportToExcel:', error);
      if (error instanceof Error) {
        console.error('Error message:', error.message);
        console.error('Error stack:', error.stack);
      }
    }
  };

  return (
    <Box>
      <Box>
        <ChildHeader text="NON CONFORMANCE REPORT">
          {!isView && (
            <>
              <CustomButton
                sx={{
                  backgroundColor: '#1c7ed6',
                }}
                onClick={async () => {
                  const ans = await confirmAddRow();
                  if (!ans) return;
                  moduleData.processes.push(
                    lodash.cloneDeep({
                      is_added_manually: true,
                      s_no: moduleData.processes.length + 1,
                    })
                  );
                  setRender((prev) => !prev);
                }}
              >
                ADD ROWS
              </CustomButton>
              <SubmitButton
                label={moduleData._id ? `UPDATE` : `SUBMIT`}
                onClick={() => onSubmit()}
              />
            </>
          )}

<CustomButton
            component="label"
            sx={{
              backgroundColor: '#2b8a3e',
              marginRight: '10px'
            }}
            disabled={isUploading}
          >
            {isUploading ? 'Uploading...' : 'Upload Excel'}
            <input
              type="file"
              hidden
              accept=".xlsx,.xls"
              onChange={handleFileUpload}
            />
          </CustomButton>
          
          <CustomButton
            sx={{
              backgroundColor: '#2b8a3e',
            }}
            onClick={exportToExcel}
          >
            Download Excel
          </CustomButton>
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          handelDeleteRows,
          isView,
          setIsOpenPopup,
          setPartIdx,
          onSubmit,
          setModuleData,
        }}
      />
      <DialogAddRow />
      <DialogDeleteRow />
      <ModalCustom
        title="Part List"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <PickPart moduleData={moduleData.processes[partIdx]} />
      </ModalCustom>
    </Box>
  );
};

export default Create;

import TableCompHead from '../../../../../components/ui/TableCompHead';
import { StyledTableHeaderRow } from '../../../../../components/ui/tableFreeze/RowFreeze.styled';

const head1 = [
    'JOB. NO.',
    'SR. NO.',
    'Day',
    'PLANNED QTY',
    'PART NAME',
    'JOB COMPLETION %', //Changed from %job description
    'ACTUAL QTY',
    'STROKE/MIN',
    'TOTAL TIME REQUIRED IN MINS FOR JOB COMPLETION',
    'TOTAL DOWNTIME',
    'START DATE',
    'START TIME', //Added
    'PREDICTED COMPLETION DATE',
    'PREDICTED COMPLETION TIME', //Added
    'ACTUAL COMPLETION DATE',
    'ACTUAL COMPLETION TIME',
    'DAYWISE SUMMARY',
    'ACTION',
];

const style1 = {
    backgroundColor: '#4F81BD',
    color: '#fff',
    minWidth: '130px',
};
const THead = () => {
    return (
        <thead>
            <TableCompHead
                colSpan={29}
                rowSpan={4}
                labels={[]}
                values={[]}
                heading="Planning Dashboard"
            />
            <StyledTableHeaderRow>
                {head1.map((heading, index) => (
                    <th key={heading + index} rowSpan={4} style={{ ...style1 }}>
                        {heading}
                    </th>
                ))}
            </StyledTableHeaderRow>
        </thead>
    );
};

export default THead;

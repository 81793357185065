import axios from 'axios';
import { useEffect, useState } from 'react';
import { TableInput } from '../../../../../components/common';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import { StyledTableHeaderRow } from '../../../../../components/ui/tableFreeze/RowFreeze.styled';

interface IProps {
  moduleData: any;
  isView: boolean;
  setUseEffectRender: any;
  setMachineId: any;
  machineId: any;
}

const head1 = [
  'S.NO.',
  'DATE',
  'SHIFT',
  'SHIFT START TIME',
  'SHIFT  END TIME',
  'TOTAL SCHEDULED DOWNTIME',
  'REASONS OF SCHEDULED DOWNTIME',
  'TOTAL UNSCHEDULED DOWNTIME',
  'REASONS OF UNSCHEDULED DOWNTIME',
  'PROD. ORDER NO.',
  'ITEM NO.',
  'TOOLS',
  'OPERATOR',
  'WORKING MINUTES',
  'TOTAL RUN TIME (IN MINUTES)',
  'PRODUCED QUANTITY WITH CHIPS (KGS)',
  'OK QTY WITHOUT CHIPS (KGS)',
  'CHIPS QTY (KGS)',
  'HOLD QTY (KGS)',
  'WEIGHT/PC (KGS)',
  'OK QTY WITHOUT CHIPS (PCS)',
  'HOLD QTY (PCS)',
];
const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '100px',
};

const THead = ({
  moduleData,
  isView,
  setUseEffectRender,
  machineId,
  setMachineId,
}: IProps) => {
  const [render, setRender] = useState(false);
  const [machines, setMachines] = useState([]);
  useEffect(() => {
    axios
      .get('/api/machineRoutes/machine/ForAutocomplete')
      .then((res) => {
        setMachines(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <thead>
      <TableCompHead
        colSpan={24}
        rowSpan={4}
        labels={['DOC NO.', 'ISSUE DATE', 'REVISION NO.', 'REVISION DATE']}
        values={['F PRD 15', '01/12/2019', '03', '1/12/22']}
        heading="DAILY PRODUCTION REPORT - RIVET BODY"
      />
      <tr>
        <th style={{ minWidth: '100px' }}>M/C NO.: </th>
        <th colSpan={5}>
          <AutocompleteMuiCustom
            id="Machine"
            label=""
            option_name="machine_no"
            arrayofObj={machines || []}
            value={machineId || ''}
            onChange={(e, value) => {
              setMachineId(value);
            }}
          />
        </th>
        <th align="left">SHIFT SCHEDULED:</th>
        <th colSpan={3} align="left">
          <TableInput
            isView={isView}
            name="shift_schedule"
            type="text"
            value={moduleData.shift_schedule}
            onChange={(e) => {
              moduleData.shift_schedule = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={1}>FORCAST:</th>
        <th colSpan={3} align="left">
          <TableInput
            isView={isView}
            name="forcast"
            type="text"
            value={moduleData.forcast}
            onChange={(e) => {
              moduleData.forcast = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={10}></th>
      </tr>
      <StyledTableHeaderRow>
        {head1.map((heading, index) => (
          <th key={heading + index} rowSpan={2} style={{ ...style1 }}>
            {heading}
          </th>
        ))}
        <th rowSpan={2} colSpan={2} style={{ ...style1 }}>
          REMARKS
        </th>
      </StyledTableHeaderRow>
    </thead>
  );
};

export default THead;

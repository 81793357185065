import ExcelJS from 'exceljs';
import moment from 'moment';
import axios from 'axios';

interface Machine {
  _id: string;
  machine_name: string;
  machine_no: string;
  sub_department_id: string;
}

interface Defect {
  _id: string;
  defect_name: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface ProcessData {
  s_no: number;
  date: Date;
  time: Date | null;
  stage: string;
  machine_no: string;
  machine_id: string | null;
  defect_id: string | null;
  production_order_no: string;
  item_code: string;
  suspected_qty: number;
  uom: string;
  nature_of_nc: string;
  containment_action: string;
  root_cause: string;
  action_taken: string;
  ok_qty: number;
  scrap_qty: number;
  reworked_qty: number;
  accepted_qty: number;
  qa_sign: string;
  is_added_manually: boolean;
  prd_sign: string;
  remarks: string;
}

interface ParsedData {
  annual_month: string;
  processes: ProcessData[];
}

const getCellValue = (cell: ExcelJS.Cell): string => {
  if (cell.value === null || cell.value === undefined) return '';
  
  if (typeof cell.value === 'object') {
    if ('text' in cell.value) {
      return cell.value.text || '';
    }
    if ('result' in cell.value) {
      return cell.value.result?.toString() || '';
    }
    return '';
  }
  
  return cell.value.toString();
};

const validateAndTransform = {
  number: (value: any): number => {
    if (value === null || value === undefined || value === '') return 0;
    const num = Number(value);
    return isNaN(num) ? 0 : num;
  },

  string: (value: any): string => {
    if (value === null || value === undefined) return '';
    return String(value).trim();
  },

  date: (cell: ExcelJS.Cell): Date | null => {
    if (!cell.value) return null;

    let dateValue: Date | null = null;

    if (cell.value instanceof Date) {
      dateValue = cell.value;
    } else if (typeof cell.value === 'number') {
      dateValue = moment(cell.value).toDate();
    } else {
      const dateStr = getCellValue(cell);
      dateValue = moment(dateStr, ['DD/MM/YYYY', 'YYYY-MM-DD', 'MM/DD/YYYY'], true).toDate();
    }

    if (!dateValue) return null;

    // Convert to the required UTC time (previous day 18:30:00 UTC)
    const utcDate = moment(dateValue).startOf('day');
    return utcDate.toDate();
  },

  time: (cell: ExcelJS.Cell, baseDate: Date): Date | null => {
    if (!cell.value) return null;

    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    // Case 1: If it's already a Date object (Excel time stored as number)
    if (cell.value instanceof Date) {
        // Convert Excel's Date object to UTC time
        const utcDate = moment(cell.value).utc();
        hours = utcDate.hours();
        minutes = utcDate.minutes();
        seconds = utcDate.seconds();
    }
    // Case 2: If it's a string in format "HH : mm : ss" or "HH:mm:ss"
    else {
        const timeStr = getCellValue(cell);
        
        // Try "HH : mm : ss" format first
        let timeParts = timeStr.split(' : ').map(part => parseInt(part.trim()));
        
        // If that didn't work, try "HH:mm:ss" format
        if (timeParts.length !== 3 || timeParts.some(isNaN)) {
            timeParts = timeStr.split(':').map(part => parseInt(part.trim()));
        }

        // If we got valid parts, use them
        if (timeParts.length >= 2 && !timeParts.some(isNaN)) {
            [hours, minutes] = timeParts;
            seconds = timeParts[2] || 0;
        } else {
            return null;
        }
    }

    // Validate time components
    if (hours < 0 || hours >= 24 || minutes < 0 || minutes >= 60 || seconds < 0 || seconds >= 60) {
        return null;
    }

    // Start with the base date (which is previous day 18:30:00 UTC)
    const timeDate = moment(baseDate);
    
    // Add the hours and minutes
    timeDate.add(hours, 'hours').add(minutes, 'minutes');

    return timeDate.toDate();
}
};

export const parseExcel = async (file: File): Promise<ParsedData> => {
  try {
    // Fetch machines and defects lists
    const [machinesResponse, defectsResponse] = await Promise.all([
      axios.get('/api/machineRoutes/machine/ForAutocomplete'),
      axios.get('/api/defectRoutes/defectList/defectForAutoComplete')
    ]);

    const machines: Machine[] = machinesResponse.data;
    const defects: Defect[] = defectsResponse.data;

    // Helper functions for finding matches
    const findMachineId = (machineNo: string): string | null => {
      const normalizedMachineNo = machineNo.replace('MN-', '');
      const machine = machines.find(m => 
        m.machine_no.replace('MN-', '').toLowerCase() === normalizedMachineNo.toLowerCase()
      );
      return machine ? machine._id : null;
    };

    const findDefectId = (natureOfNc: string): string | null => {
      const defect = defects.find(d => 
        d.defect_name.toLowerCase() === natureOfNc.toLowerCase()
      );
      return defect ? defect._id : null;
    };

    const workbook = new ExcelJS.Workbook();
    const arrayBuffer = await file.arrayBuffer();
    await workbook.xlsx.load(arrayBuffer);

    const worksheet = workbook.getWorksheet(1);
    if (!worksheet) throw new Error('No worksheet found');

    // Get the first data row (row 2, after headers)
    const firstDataRow = worksheet.getRow(2);
    if (!firstDataRow.values) throw new Error('No data found');

    // Extract date from first row and determine annual_month
    const firstRowDate = validateAndTransform.date(firstDataRow.getCell(2));
    if (!firstRowDate) throw new Error('No valid date found in first row');
    
    const annual_month = moment(firstRowDate).add(5, 'hours').add(30, 'minutes').format('MMM-YYYY').toUpperCase();

    const processes: ProcessData[] = [];
    
    // Start from row 2 (after headers)
    for (let rowNumber = 2; rowNumber <= worksheet.rowCount; rowNumber++) {
      const row = worksheet.getRow(rowNumber);
      if (!row.values || row.values.length === 0) continue;

      // Get date and validate
      const rowDate = validateAndTransform.date(row.getCell(2));
      if (!rowDate) continue;

      // Get time using the calculated date as base
      const rowTime = validateAndTransform.time(row.getCell(3), rowDate);
      //if (!rowTime) continue;

      // Get machine_no and find corresponding machine_id
      const machineNo = validateAndTransform.string(getCellValue(row.getCell(5)));
      const machineId = findMachineId(machineNo);

      // Get nature_of_nc and find corresponding defect_id
      const natureOfNc = validateAndTransform.string(getCellValue(row.getCell(10)));
      const defectId = findDefectId(natureOfNc);

      const process: ProcessData = {
        s_no: validateAndTransform.number(getCellValue(row.getCell(1))),
        date: rowDate,
        time: rowTime || null,
        stage: validateAndTransform.string(getCellValue(row.getCell(4))),
        machine_no: machineNo,
        machine_id: machineId,
        defect_id: defectId,
        production_order_no: validateAndTransform.string(getCellValue(row.getCell(6))),
        item_code: validateAndTransform.string(getCellValue(row.getCell(7))),
        suspected_qty: validateAndTransform.number(getCellValue(row.getCell(8))),
        uom: validateAndTransform.string(getCellValue(row.getCell(9))),
        nature_of_nc: natureOfNc,
        containment_action: validateAndTransform.string(getCellValue(row.getCell(11))),
        root_cause: validateAndTransform.string(getCellValue(row.getCell(12))),
        action_taken: validateAndTransform.string(getCellValue(row.getCell(13))),
        ok_qty: validateAndTransform.number(getCellValue(row.getCell(14))),
        scrap_qty: validateAndTransform.number(getCellValue(row.getCell(15))),
        reworked_qty: validateAndTransform.number(getCellValue(row.getCell(16))),
        accepted_qty: validateAndTransform.number(getCellValue(row.getCell(17))),
        qa_sign: validateAndTransform.string(getCellValue(row.getCell(18))),
        prd_sign: validateAndTransform.string(getCellValue(row.getCell(19))),
        remarks: validateAndTransform.string(getCellValue(row.getCell(20))),
        is_added_manually: true
      };

      if (process.production_order_no || process.item_code || process.suspected_qty > 0) {
        processes.push(process);
      }
    }

    if (processes.length === 0) {
      throw new Error('No valid data rows found in Excel file');
    }

    return {
      annual_month,
      processes
    };
  } catch (error) {
    console.error('Error parsing Excel:', error);
    throw error;
  }
};




//////////////////////////
/////////////////////////